import { DatePipe } from '@angular/common'
import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { loadDynamicMedia, selectItemSelected, selectMediaMap } from '@bh/design-system'
import { FaceDetection, ProcessResult } from '@bh/face-detection'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { FileSharer } from '@byteowls/capacitor-filesharer'
import { Share } from '@capacitor/share'
import {
    DateUtil,
    Dependant,
    DocumentItem,
    DocUnitItem,
    getMemoryShareability,
    loadActivityFeed,
    loadActivityFeedDomain,
    loadCenterInfo,
    Media,
    MediaType,
    memoryDetailsClosed,
    MemoryUtil,
    PhotoItem,
    PhotoPipe,
    PhotoUnitItem,
    Profile,
    removeAllRefreshActions,
    selectActiveDocumentData,
    selectActiveDocumentShareable,
    selectCenterInfo,
    selectDependantMap,
    selectGroupedDocsDetail,
    selectGroupedPhotosDetail,
    selectMemoriesUrl,
    selectProfile,
    selectTabSelected,
    MediaShareType,
    presentToast,
    ExtensionsObj,
    IDailyReportMap,
    downloadDailyReport,
    shareDailyReport,
    selectGroupedActivityForDependentOnDate,
    ActivityFeed,
    loadMultiMemoriesByPeriod,
    DailyReportPipe,
    MemoriesTabType,
    ToastLevel
} from '@events'
import { AlertController, IonContent, Platform } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { SortMultiAttachment } from '@shared'
import moment from 'moment'
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription } from 'rxjs'
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import Swiper, { SwiperOptions } from 'swiper'
import { SwiperComponent } from 'swiper/angular'
import { UnsubscriberComponent, AnalyticsService } from '@bh/security'
import { DocumentDetailItemType } from '../detail-item/detail-item.component'
import { MemoryDetailsComponent } from '../memory-details/memory-details.component'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Media as CapacitorMedia, MediaAlbum, MediaAlbumResponse } from '@capacitor-community/media'

@Component({
    selector: 'bh-document-detail-list',
    templateUrl: './detail-list.component.html',
    styleUrls: ['./detail-list.component.scss']
})
export class DocumentDetailListComponent
    extends UnsubscriberComponent
    implements OnInit, OnDestroy
{
    @Input() id = ''
    @Input() dependentId = ''
    @Input() centerId = ''
    @Input() type = ''
    @Input() isShareAvailable = false
    @Input() sortDate?: Date = new Date()
    @Input() attachmentId?: string = ''
    @Input() description?: string = ''
    @Input() domain?: string = ''
    @Input() attribute?: string = ''
    @Input() selections?: any[] = []
    @Input() title? = ''
    @Input() witwhtDescription? = ''
    @Input() memoryId = ''
    @Input() mediaType?: MediaType
    @Input() limitDate: boolean | undefined
    @Input() activityType?: string = ''
    @Input() dependantIdList: string[] = []

    @ViewChild('swiper', { static: false }) slides?: SwiperComponent

    @ViewChild(IonContent) content: IonContent | undefined

    public dependant$: Observable<Dependant>
    public homeLink$: Observable<string>
    public isShareable$: Observable<boolean | null>
    private loggerService: LoggerService
    private profile$: Observable<Profile>
    private lastLoadedMonth: string = ''

    public isSwiping = false
    public alertVisible = false
    public firstLoadedMonth: string = ''
    public isAddingMemoryitem = false
    public userChangedSlide = false

    public slideOpts: SwiperOptions = {
        initialSlide: 0,
        slidesPerView: 1,
        autoplay: false
    }

    public detailSlideList = [] as DocumentDetailItemType[]

    public memoryList$: Observable<PhotoItem[] | DocumentItem[]>

    public slideIndex = 1

    public backSubscription?: Subscription

    public readonly mediaShareType = MediaShareType

    public reportSubject$: BehaviorSubject<{ dependantId: string; date: Date }> =
        new BehaviorSubject({
            dependantId: '',
            date: new Date()
        })
    public report$: Observable<ActivityFeed> = of(new ActivityFeed({}))
    private selectedTab: MemoriesTabType = 'media'
    private childFilter: any = null
    public pauseAnyMedia = false

    constructor(
        private store: Store,
        private ngZone: NgZone,
        private alertController: AlertController,
        private translateService: TranslateService,
        private sortMultiAttachment: SortMultiAttachment,
        private datePipe: DatePipe,
        private photoPipe: PhotoPipe,
        private loggerFactory: LoggerFactory,
        private platform: Platform,
        private dailyReportPipe: DailyReportPipe,
        private analyticsService: AnalyticsService
    ) {
        super()
        this.loggerService = this.loggerFactory.getLogger('DocumentDetailListComponent')
        this.profile$ = this.store.select(selectProfile).pipe(
            takeUntil(this.destroy$),
            filter((p) => !!p),
            map((p) => p as Profile)
        )
        this.store.dispatch(loadActivityFeedDomain())
        this.homeLink$ = this.store.select(selectMemoriesUrl)

        this.isShareable$ = this.store
            .select(selectActiveDocumentShareable)
            .pipe(takeUntil(this.destroy$))
        this.dependant$ = this.store.select(selectDependantMap).pipe(
            filter(() => this.dependentId !== ''),
            map((map) => map[this.dependentId]),
            filter((dependent) => !!dependent),
            takeUntil(this.destroy$),
            take(1)
        )

        combineLatest([this.dependant$, this.store.select(selectCenterInfo)])
            .pipe(
                tap((dataArr) => {
                    if (this.centerId && dataArr[1][this.centerId] === undefined) {
                        this.store.dispatch(loadCenterInfo({ centerInfoId: this.centerId }))
                    }
                }),
                filter((dataArr) => dataArr[0] && Object.keys(dataArr[1]).length === 0),
                takeUntil(this.destroy$),
                take(1)
            )
            .subscribe((dataArr) => {
                this.store.dispatch(loadCenterInfo({ centerInfoId: dataArr[0].centerId }))
            })

        const tabSelected = this.store.select(selectTabSelected)
        const activeDocumentData = this.store.select(selectActiveDocumentData)
        const itemSelected = this.store.select(selectItemSelected) as Observable<Dependant>

        this.memoryList$ = combineLatest([tabSelected, itemSelected, activeDocumentData]).pipe(
            switchMap(([tabSelected, itemSelected, activeDocumentData]) => {
                this.selectedTab = tabSelected
                this.childFilter = itemSelected
                const grouped =
                    this.selectedTab !== 'media' && MemoryUtil.isDocument(activeDocumentData)
                        ? this.store.select(selectGroupedDocsDetail)
                        : this.store.select(selectGroupedPhotosDetail)
                this.activityType = activeDocumentData?.activityType

                return from(grouped).pipe(
                    take(1),
                    map((data: any) => data['daily']),
                    map(this.handleMemoryList.bind(this))
                )
            }),
            takeUntil(this.destroy$)
        )

        // Normally we'd use the standard 10 for priority, but since we're stacking modals and pages
        // there is an Ionic issue where using too low a priority results in this event getting ignored.
        // The minimum priority we can use is 101 as Ionic has a default priority of 100 for any
        // page on the stack of items that can be dismissed via the native back button.
        this.backSubscription = this.platform.backButton.subscribeWithPriority(101, () => {
            this.closeMemoryDetails()
        })

        this.report$ = this.reportSubject$.pipe(
            filter((res) => !!res.dependantId),
            switchMap(({ dependantId, date }) => {
                return this.store
                    .select(
                        selectGroupedActivityForDependentOnDate(
                            dependantId,
                            DateUtil.localToUtc(new Date(date)).toISOString().split('T')[0]
                        )
                    )
                    .pipe(filter((feed: any) => !!Object.keys(feed).length))
            })
        )
    }

    handleMemoryList(daily: any) {
        const list = (daily as []).reduce((acc, unitItem: PhotoUnitItem | DocUnitItem) => {
            return acc.concat(unitItem.units)
        }, [] as any[])
        let results = list
        if (this.limitDate) {
            results = list.filter(
                (l) =>
                    moment(l.sortDate).format('yyyy-MM-DD') ===
                    moment(this.sortDate).format('yyyy-MM-DD')
            )
        }
        if (this.childFilter !== 'All' && this.childFilter !== undefined) {
            results = results.filter((l) => l.dependantId === this.childFilter.id)
        }
        results = results.sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime())
        return results
    }

    public ngOnInit(): void {
        this.store.dispatch(removeAllRefreshActions())

        if (this.type === 'report') {
            const itemDate = this.datePipe.transform(this.sortDate, 'yyyy-MM-dd')
            this.reportSubject$.next({
                dependantId: this.dependentId,
                date: moment(itemDate).toDate()
            })
        }

        this.memoryList$
            .pipe(
                take(1),
                tap((list) => {
                    this.detailSlideList = [
                        {
                            id: this.id,
                            dependentId: this.dependentId,
                            type: this.type,
                            isShareAvailable: this.isShareAvailable,
                            title: this.title,
                            witwhtDescription: this.witwhtDescription,
                            sortDate: this.sortDate,
                            centerId: this.centerId,
                            attachmentId: this.attachmentId,
                            description: this.description,
                            domain: this.domain,
                            attribute: this.attribute,
                            selections: this.selections,
                            isDailyReport: this.type === 'report',
                            memoryId: `${this.id}_${this.dependentId}`,
                            activityType: this.activityType
                        } as DocumentDetailItemType
                    ]

                    const uniqueList: string[] = [this.id]
                    for (let i = 0, len = list.length; i < len; i++) {
                        if (list[i].memoryId !== this.memoryId && this.memoryId !== '') {
                            continue
                        }
                        if (i > 0) {
                            const prevExists = uniqueList.includes(list[i - 1].id)
                            if (!prevExists) {
                                this.addMemoryItemBefore(list[i - 1], true)
                                uniqueList.push(list[i - 1].id)
                            }
                        }
                        if (i + 1 < len) {
                            const nextExists = uniqueList.includes(list[i + 1].id)
                            if (!nextExists) {
                                this.addMemoryItemAfter(list[i + 1])
                                uniqueList.push(list[i + 1].id)
                            }
                        }
                    }
                })
            )
            .toPromise()

        this.store
            .select(selectMediaMap)
            .pipe(
                filter(() => !!this.slides?.swiperRef),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.slides?.swiperRef.updateAutoHeight()
            })
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
        this.backSubscription?.unsubscribe()
        this.reportSubject$.complete()
    }

    public closeMemoryDetails() {
        this.store.dispatch(memoryDetailsClosed({ id: this.id }))
    }

    public async addMemoryItemBefore(
        memoryItem: PhotoItem | DocumentItem,
        shouldSlide = false
    ): Promise<any> {
        const item = await this.formatItem(memoryItem)
        this.detailSlideList.unshift(item as DocumentDetailItemType)

        if (shouldSlide) {
            this.slides?.swiperRef.slideTo(1, 0)
        }

        const monthLoad = moment(memoryItem.sortDate)
            .add(1, 'M')
            .endOf('month')
            .format('YYYY-MM-DD')

        if (!this.firstLoadedMonth || moment(this.firstLoadedMonth).isBefore(moment(monthLoad))) {
            const monthEnd = moment(item.sortDate).startOf('month').format('YYYY-MM-DD')
            const loadReports = MemoryUtil.isDocument(memoryItem)
            this.store.dispatch(
                loadMultiMemoriesByPeriod({
                    memoryMediaRequest: {
                        dependentIdList: this.dependantIdList,
                        startDate: monthEnd,
                        endDate: monthLoad
                    },
                    loadMedia: !loadReports,
                    loadReports
                })
            )
        }

        this.firstLoadedMonth = monthLoad
    }

    public async addMemoryItemAfter(memoryItem: PhotoItem | DocumentItem): Promise<void> {
        const item = await this.formatItem(memoryItem)
        this.detailSlideList.push(item)

        const monthLoad = moment(memoryItem.sortDate)
            .add(1, 'M')
            .endOf('month')
            .format('YYYY-MM-DD')

        if (!this.lastLoadedMonth || moment(this.lastLoadedMonth).isAfter(moment(monthLoad))) {
            const monthEnd = moment(item.sortDate).startOf('month').format('YYYY-MM-DD')
            const loadReports = MemoryUtil.isDocument(memoryItem)
            this.store.dispatch(
                loadMultiMemoriesByPeriod({
                    memoryMediaRequest: {
                        dependentIdList: this.dependantIdList,
                        startDate: monthLoad,
                        endDate: monthEnd
                    },
                    loadMedia: !loadReports,
                    loadReports
                })
            )
        }

        this.lastLoadedMonth = monthLoad
    }

    public addSlideStart(e: Swiper): void {
        if (this.isMainSlide(e) && !this.isAddingMemoryitem) {
            this.memoryList$.pipe(takeUntil(this.destroy$)).subscribe((list) => {
                const targetItem = this.detailSlideList[0]
                const targetMemId = MemoryUtil.getMemoryId(targetItem.id, targetItem.dependentId)
                const index = list.findIndex((item) => {
                    const { memoryId, type } = item
                    return type === 'report' ? memoryId === targetItem.id : memoryId === targetMemId
                })

                if (this.selectedTab === 'media') {
                    this.loadDeletedMedia(index, false, list)
                } else {
                    this.handleAddingItemToSlide(index, list, false)
                }
            })
        }
    }

    public async addSlideEnd(e: Swiper): Promise<void> {
        if (this.isMainSlide(e)) {
            this.memoryList$.pipe(takeUntil(this.destroy$)).subscribe((list) => {
                const targetItem = this.detailSlideList[this.detailSlideList.length - 1]
                const targetMemId = MemoryUtil.getMemoryId(targetItem.id, targetItem.dependentId)
                const index = list.findIndex((item) => {
                    const { memoryId, type } = item
                    return type === 'report' ? memoryId === targetItem.id : memoryId === targetMemId
                })
                this.slideIndex = index

                if (index < list.length - 1 && index !== -1) {
                    if (this.selectedTab === 'media') {
                        this.loadDeletedMedia(index, true, list)
                    } else {
                        this.handleAddingItemToSlide(index, list, true)
                    }
                }
            })
        }
    }

    loadDeletedMedia(index: number, isAddingAfter: boolean, list: PhotoItem[] | DocumentItem[]) {
        const listItem = list[isAddingAfter ? index + 1 : index - 1]
        if (index > 0 && !listItem?.memoryId) {
            this.store.dispatch(
                loadMultiMemoriesByPeriod({
                    memoryMediaRequest: {
                        dependentIdList: this.dependantIdList,
                        startDate: moment(listItem.sortDate).format('YYYY-MM-DD'),
                        endDate: moment(listItem.sortDate).format('YYYY-MM-DD')
                    },
                    loadMedia: true,
                    loadReports: false
                })
            )

            this.store
                .select(selectGroupedPhotosDetail)
                .pipe(
                    map((data: any) => data['daily']),
                    map(this.handleMemoryList.bind(this)),
                    filter((data) => {
                        const dataItem = data[isAddingAfter ? index + 1 : index - 1]
                        return dataItem?.memoryId && dataItem?.dependantId === listItem?.dependantId
                    }),
                    take(1),
                    takeUntil(this.destroy$)
                )
                .subscribe((items) => {
                    this.handleAddingItemToSlide(index, list, isAddingAfter, items)
                })
        } else {
            this.handleAddingItemToSlide(index, list, isAddingAfter)
        }
    }

    handleAddingItemToSlide(
        index: number,
        list: PhotoItem[] | DocumentItem[],
        isAddingAfter: boolean,
        items?: PhotoItem[] | DocumentItem[]
    ) {
        if (isAddingAfter) {
            const find = this.detailSlideList.findIndex(
                (x: any) => x.memoryId === list[index + 1].memoryId
            )
            if (find === -1) {
                this.addMemoryItemAfter(items ? items[index + 1] : list[index + 1])
                this.memoryId = items ? items[index + 1].id : list[index + 1].id
            }
        } else if (index > 0) {
            // The isAddingMemoryitem is preventing an issue where addMemoryItemBefore() triggers SwiperJS's call
            // to addSlideStart()- which creates an infinite callback loop. This flag merely prevents addMemoryItemBefore()
            // from causing further calls to addSlideStart().
            this.isAddingMemoryitem = true
            this.addMemoryItemBefore(items ? items[index - 1] : list[index - 1], true)
            this.isAddingMemoryitem = false
        }
    }

    public async formatItem(memoryItem: PhotoItem | DocumentItem): Promise<DocumentDetailItemType> {
        let item = {}

        if (MemoryUtil.isDocument(memoryItem)) {
            const docItem = (await MemoryDetailsComponent.getDocumentSelected(
                of(memoryItem.memoryId),
                this.store,
                false
            ).toPromise()) as DocumentItem
            item = {
                id: docItem.id,
                memoryId: docItem.memoryId,
                dependentId: docItem.dependantId,
                type: docItem.type,
                isShareAvailable: docItem.type === 'observation',
                sortDate: docItem.sortDate,
                centerId: docItem.centerId,
                attachmentId: docItem.attachment,
                description: docItem.description,
                domain: docItem.domain,
                attribute: docItem.attribute,
                selections: docItem.selections
                    ? this.sortMultiAttachment.transform(docItem.selections)
                    : [],
                title: docItem.title,
                witwhtDescription: docItem.description,
                isDailyReport: docItem.type === 'report',
                activityType: docItem.type
            }
            return item as DocumentDetailItemType
        } else {
            const photoItem = (await MemoryDetailsComponent.getMediaSelected(
                of(memoryItem.memoryId),
                this.store,
                false
            ).toPromise()) as PhotoItem
            item = {
                id: photoItem.id,
                memoryId: photoItem.memoryId,
                dependentId: photoItem.dependantId,
                type: 'snapshot',
                isShareAvailable: true,
                sortDate: photoItem.sortDate,
                attachmentId: photoItem.attachmentId,
                description: photoItem.note,
                title: photoItem.title,
                witwhtDescription: photoItem.description,
                domain: this.domain,
                attribute: this.attribute,
                selections: photoItem.selections
                    ? this.sortMultiAttachment.transform(photoItem.selections)
                    : [],
                isDailyReport: false,
                activityType: photoItem.activityType
            }
            return item as DocumentDetailItemType
        }
    }

    public async processFile(media: Media, processType: MediaShareType): Promise<string> {
        if (processType === MediaShareType.download) {
            this.analyticsService.logEvent('file_download', {
                link_text: 'download',
                link_url: media.url,
                file_name: media.fileName,
                file_extension: media.contentType
            })
        }
        
        const canShare = await this.isShareable$.pipe(take(1)).toPromise()

        if (!canShare) {
            await this.presentDownloadNotAvailable()
            return media.url
        }

        if (this.mediaType) {
            try {
                const result: ProcessResult = await FaceDetection.process({
                    type: media.contentType,
                    image: media.url
                })
                if (result.faces?.length > 1) {
                    await this.presentPrivacyAlert(result.base64Image, media, processType)
                    return media.url
                }

                this.processMediaType(processType, result.base64Image, media)
            } catch (e) {
                this.loggerService.error(e)
            }
        } else {
            Share.share({
                title: '1 Selected',
                text: 'My Bright Day 2.0',
                url: media.url,
                dialogTitle: 'Bright Horizons'
            })
        }

        return media.url
    }

    public async shareFile(data: string, media: Media): Promise<boolean> {
        try {
            const extension = this.mediaExtension(media)

            await FileSharer.share({
                filename: `${media.fileName}.${extension}`,
                base64Data: data,
                contentType: media.contentType
            })
            return true
        } catch (error) {
            this.loggerService.error('File sharing failed', error)
            return false
        }
    }

    public async presentDownloadNotAvailable(): Promise<void> {
        if (!(await this.alertController.getTop())) {
            combineLatest([
                this.translateService.get('memories.download-not-available-header'),
                this.translateService.get('memories.download-not-available')
            ])
                .pipe(takeUntil(this.destroy$))
                .subscribe(async (dataArr) => {
                    const alert = await this.alertController.create({
                        cssClass: '',
                        header: dataArr[0],
                        subHeader: '',
                        message: dataArr[1],
                        buttons: ['OK']
                    })
                    alert.present()
                    this.alertVisible = true
                })
        }
    }

    public async presentPrivacyAlert(
        data: string,
        media: Media,
        processType: MediaShareType
    ): Promise<void> {
        if (!(await this.alertController.getTop())) {
            combineLatest([
                this.translateService.get('memories.privacy-warning-alert-title'),
                this.translateService.get('memories.privacy-warning-alert-description')
            ])
                .pipe(takeUntil(this.destroy$))
                .subscribe(async (dataArr) => {
                    const header = dataArr[0]
                    const message = dataArr[1]
                    const alert = await this.alertController.create({
                        cssClass: '',
                        header: header,
                        subHeader: '',
                        message: message,
                        buttons: [
                            { text: 'Cancel', role: 'cancel' },
                            {
                                text: 'Save',
                                handler: () => {
                                    this.processMediaType(processType, data, media)
                                }
                            }
                        ]
                    })
                    alert.present()
                })
        }
    }

    public processMediaType(processType: MediaShareType, data: string, media: Media): void {
        if (processType === this.mediaShareType.share) {
            this.shareFile(data, media)
        } else if (processType === this.mediaShareType.download) {
            this.downloadMedia(data, media)
        }
    }

    public onSlideWillChange(e: Swiper) {
        if (this.type !== 'report') {
            this.scrollToTop()
        }
        const index = this.slides?.swiperRef.activeIndex
        if (index === undefined) {
            return
        }
        if (this.isMainSlide(e) && index < this.detailSlideList.length - 1) {
            this.isSwiping = true
        }
    }

    public onSliderMover() {
        this.userChangedSlide = true
    }

    public slideChangeTransitionStart(){
        // Pause any media when swiping
        this.pauseAnyMedia = true
    }

    public slideChangeTransitionEnd(){
        // Reset value to false after swipe
        this.pauseAnyMedia = false
    }

    public onSlideChange(e: Swiper) {
        if (this.isMainSlide(e)) {
            const index = this.slides?.swiperRef.activeIndex
            if (
                index !== undefined &&
                index > -1 &&
                index < this.detailSlideList.length &&
                this.userChangedSlide
            ) {
                const detailItem = this.detailSlideList[index]

                this.attachmentId = detailItem.attachmentId
                this.type = detailItem.type

                if (detailItem.isDailyReport) {
                    const itemDate = this.datePipe.transform(detailItem.sortDate, 'yyyy-MM-dd')
                    this.ngZone.run(() => {
                        this.store.dispatch(
                            loadActivityFeed({
                                date: moment(itemDate).toDate(),
                                dependantId: detailItem.dependentId
                            })
                        )
                        this.reportSubject$.next({
                            dependantId: detailItem.dependentId,
                            date: moment(itemDate).toDate()
                        })
                    })
                } else {
                    this.profile$.pipe(take(1)).subscribe((profile) => {
                        this.ngZone.run(() => {
                            this.store.dispatch(
                                getMemoryShareability({
                                    guardianId: profile!.id,
                                    memoryId: detailItem.id,
                                    memoryType: detailItem.type,
                                    activityType: detailItem.activityType
                                })
                            )
                            this.photoPipe
                                .transform(detailItem.attachmentId)
                                .pipe(takeUntil(this.destroy$))
                                .subscribe((item) => (this.mediaType = item.shortContentType))

                            if (this.mediaType !== 'image') {
                                this.store.dispatch(
                                    loadDynamicMedia({
                                        id: detailItem.attachmentId,
                                        thumbnail: false
                                    })
                                )
                            }
                        })
                    })
                }
                /* Need to set userChangedSlide to false to prevent duplicate calls if the user
                    is swiping backwards, onSlideChange() can get called twice */
                this.userChangedSlide = false
            }
            this.scrollToTop()
            this.isSwiping = false
        }
    }

    public isMainSlide(e: Swiper): boolean {
        return (e.el as Element).className.includes('main-slides')
    }

    public scrollToTop(): void {
        if (this.content) {
            this.content.scrollToTop(300)
        }
    }

    /**
     * allowTouchMove set and reset is handled to prevent issues from swiping animation/slide too fast since we are tracking slides
     * separately from the SwiperJS API.
     */

    public touchStart(): void {
        this.slides!.swiperRef.updateAutoHeight()
        if (this.slides) {
            this.slides.swiperRef.allowTouchMove = true
        }
    }

    public touchEnd() {
        if (this.slides) {
            this.slides.swiperRef.allowTouchMove = false
        }
    }

    public async downloadMedia(data: string, media: Media): Promise<boolean> {
        try {
            const MediaTemp = await this.writeFile(data, media)
            const albumName = 'BH Album'
            let albumIdentifier
            const albums = await CapacitorMedia.getAlbums()
            if (this.platform.is('capacitor')) {
                albumIdentifier = albums.albums.find((a: MediaAlbum) => a.name === albumName)
                if (!albumIdentifier) {
                    await CapacitorMedia.createAlbum({ name: albumName })
                }
            }
            from(this.getDownloadType(MediaTemp.uri, albumName, albums, media.shortContentType))
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    () => {
                        this.store.dispatch(
                            presentToast({
                                message: 'Media saved to Library',
                                level: ToastLevel.Info
                            })
                        )
                    },
                    (e) => {
                        this.loggerService.error('download Media failed', e)
                    }
                )
            return true
        } catch (error) {
            this.loggerService.error('download Media failed', error)
            return false
        }
    }

    async getDownloadType(
        MediaTemp: string,
        albumName: string,
        albums: MediaAlbumResponse,
        ContentType: string
    ) {
        if (ContentType === 'video') {
            return CapacitorMedia.saveVideo({
                path: MediaTemp,
                album: this.getAlbum(albumName, albums)
            })
        }
        return CapacitorMedia.savePhoto({
            path: MediaTemp,
            album: this.getAlbum(albumName, albums)
        })
    }

    getAlbum(albumName: string, albums: MediaAlbumResponse): string {
        if (this.platform.is('ios')) {
            const albumIdentifier = albums.albums.find(
                (a: MediaAlbum) => a.name === albumName
            )?.identifier
            return albumIdentifier ?? albumName
        }
        return albumName
    }

    writeFile(data: string, media: Media) {
        const extension = this.mediaExtension(media)
        return Filesystem.writeFile({
            path: `${media.fileName}.${extension}`,
            data: data,
            directory: Directory.Cache
        })
    }

    mediaExtension(media: Media): string {
        return ExtensionsObj[media.contentType]
    }

    shareDownloadReport(reportId: string, action: string) {
        this.dailyReportPipe
            .transform(reportId)
            .pipe(take(1))
            .subscribe(
                (dailyReport: IDailyReportMap) => {
                    if (dailyReport) {
                        if (action === 'share') {
                            this.store.dispatch(shareDailyReport({ report: dailyReport }))
                        } else if (action === 'download') {
                            this.analyticsService.logEvent('file_download', { link_text: 'download', link_url: dailyReport.url, file_name: dailyReport.fileName, file_extension: dailyReport.fileName?.split('.').pop() })
                            this.store.dispatch(downloadDailyReport({ report: dailyReport }))
                        }
                    }
                },
                (e) => {
                    this.store.dispatch(
                        presentToast({
                            message:
                                'We are having trouble downloading the file. Please try again later.',
                            level: ToastLevel.Error
                        })
                    )
                }
            )
    }
}
