import { Component, Inject, NgZone, OnInit } from '@angular/core'
import { AlertController, ModalController, PickerController } from '@ionic/angular'
import { Router, NavigationStart } from '@angular/router'
import { App } from '@capacitor/app'
import { ConnectionStatus, Network } from '@capacitor/network'
import { Observable, from } from 'rxjs'
import { Store } from '@ngrx/store'
import { take, tap } from 'rxjs/operators'
import { AnalyticsService } from '@bh/security'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { selectNetworkConnected, setNetworkStatus } from '@bh/offline'
import { markDate } from '@bh/design-system'
import { DateUtil, refreshData } from '@events'
import { DOCUMENT } from '@angular/common'

@Component({
    selector: 'bh-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public networkConnection$: Observable<boolean>
    public isWeb = false
    private logger: LoggerService
    public navigationTrackers: NavigationTracker[] = [
        { url: '/activity-feed', label: 'Home' },
        { url: '/memories', label: 'Memories' },
        { url: '/my-learners', label: 'Learning' },
        { url: '/calendar', label: 'Calendar' }
    ]
    constructor(
        private readonly loggerFactory: LoggerFactory,
        private readonly store: Store,
        private readonly ngZone: NgZone,
        private readonly modalCtrl: ModalController,
        private readonly pickerController: PickerController,
        private readonly alertController: AlertController,
        private readonly router: Router,
        private readonly analyticsService: AnalyticsService,
        @Inject(DOCUMENT) protected document: Document
    ) {
        this.logger = this.loggerFactory.getLogger('AppComponent')
        this.networkConnection$ = this.store.select(selectNetworkConnected)
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.navigationTrackers.forEach((nav) => {
                    if (event.url.indexOf(nav.url) > -1) {
                        this.analyticsService.logEvent('navigation_interact', {
                            link_text: nav.label?.toLocaleLowerCase(),
                            link_url: nav.url,
                            link_section: 'footer'
                        })
                    }
                })
            }
        })
    }

    ngOnInit(): void {
        App.addListener('appStateChange', ({ isActive }) => {
            this.logger.debug(`App state changed. Is Active: ${isActive}`)
            if (isActive) {
                // added this to fix when app is in background, network change is not detected
                this.setNetworkChangeStatus()

                this.store.dispatch(markDate({ date: DateUtil.ignoreTZ(new Date()) }))
                this.store.dispatch(refreshData())
            }
        })

        Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
            this.logger.debug(
                `Network Connection status changed to ${
                    status.connected ? 'connected' : 'not connected'
                }`
            )

            if (status.connected) {
                this.store.dispatch(refreshData())
                this.modalCtrl.getTop().then((overlay) => {
                    if (overlay) {
                        overlay.hidden = false
                    }
                })
            } else {
                this.modalCtrl.getTop().then((overlay) => {
                    if (overlay) {
                        overlay.hidden = true
                    }
                })
                this.pickerController
                    .getTop()
                    .then((overlay) => (overlay ? this.pickerController.dismiss() : null))
                this.alertController
                    .getTop()
                    .then((overlay) => (overlay ? this.alertController.dismiss() : null))
            }
        }).catch((error) => this.logger.debug('Error on network connection listener', error))
    }

    setNetworkChangeStatus() {
        from(Network.getStatus())
            .pipe(
                take(1),
                tap((connectionStatus: ConnectionStatus) => {
                    this.ngZone.run(() => {
                        this.store.dispatch(
                            setNetworkStatus({ isConnected: connectionStatus.connected })
                        )
                    })
                })
            )
            .subscribe()
    }
}

export interface NavigationTracker {
    url: string
    label: string
}
