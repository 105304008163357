import { SQLiteDBService } from '@bh/data'
import { Capacitor } from '@capacitor/core'
import { Platform } from '@ionic/angular'

export function initializeSQLite(
    sqliteDBService: SQLiteDBService,
    platform: Platform
): () => Promise<boolean> {
    return async () => {
        await platform.ready()
        if (Capacitor.getPlatform() === 'web') {
            const jeepSqliteEl = document.createElement('jeep-sqlite')
            document.body.appendChild(jeepSqliteEl)
            await customElements.whenDefined('jeep-sqlite')
        }
        await sqliteDBService.initDB()
        return true
    }
}
