<ion-app>
    <ng-container
        *ngTemplateOutlet="appContainer; context: { networkStatus: (networkConnection$ | async) }"
    ></ng-container>
    <ng-template #appContainer let-networkStatus="networkStatus">
        <ion-content forceOverscroll="false">
            <ng-container>
                <ion-router-outlet
                    [ngClass]="networkStatus ? '' : 'ion-hide'"
                    [swipeGesture]="false"
                ></ion-router-outlet>
            </ng-container>
            <ng-container *ngIf="!networkStatus">
                <bh-error-page
                    [title]="'offline.title' | translate"
                    [subTitle]="'offline.subTitle' | translate"
                >
                </bh-error-page>
            </ng-container>
        </ion-content>
    </ng-template>
</ion-app>
