import { Location } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule, Optional, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { Router, RouteReuseStrategy } from '@angular/router'
import { DesignSystemModule } from '@bh/design-system'
import { ApiRetryConfig, ErrorHandlingModule, GlobalErrorHandler } from '@bh/error-handling'
import { LoggingModule } from '@bh/logging'
import {
    AuthenticationService,
    DEFAULT_ENV,
    logoutSuccess,
    NativeHttpInterceptor,
    SecurityModule,
    UserAgentInterceptor,
    APP_DETAIL,
    AppVersionService
} from '@bh/security'
import { SessionModule } from '@bh/session'
import { App } from '@capacitor/app'
import { DeepLinkingService } from '@deep-linking'
import {
    ArrivalNoteKeys,
    ParentAppVersionService,
    ParentAuthenticationService,
    ParentPersistentKeys
} from '@events'
import { IonicModule, IonicRouteStrategy, IonRouterOutlet, Platform } from '@ionic/angular'
import { LoginModule } from '@login'
import { NavModule } from '@nav'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { CalendarModule } from 'ion2-calendar'
import {
    OfflineEffects,
    OfflineReducer,
    OFFLINE_SERVICE,
    AbstractOfflineService,
    offlineFeatureKey,
    OfflineModule
} from '@bh/offline'
import packageJson from 'package.json'
import { DataModule, SQLiteDBService } from '@bh/data'

import { environment } from '../environments/environment'
import { defaultEnv } from '../environments/environment.default'
import { AppInitService } from './app-init.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { enUS } from './i18n/en-us'
import { APICancelInterceptor } from './interceptors/api-cancel.interceptor'
import { APIKeyInterceptor } from './interceptors/api-key.interceptor'
import { LBRouterInterceptor } from './interceptors/lb-router.interceptor'
import { EventsModule } from './modules/events.module'
import { initializeSQLite, clearExpiredCache, setupDeepLinks, initConfig } from './providers'

export function appInitializer(
    sqliteDBService: SQLiteDBService,
    platform: Platform,
    appLoadService: AppInitService,
    deepLinkingService: DeepLinkingService,
    offlineService: AbstractOfflineService
): () => Promise<void> {
    return async () => {
        // First, run the SQLite initializer.
        await initializeSQLite(sqliteDBService, platform)()

        // Then run the other initializers concurrently.
        await Promise.all([
            initConfig(appLoadService)(),
            setupDeepLinks(deepLinkingService)(),
            clearExpiredCache(offlineService)()
        ])
    }
}

export function clearState(reducer: any) {
    return function (state: any, action: any) {
        if (action.type === logoutSuccess.type) {
            state = undefined
        }

        return reducer(state, action)
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        OfflineModule.forRoot(),
        IonicModule.forRoot({ mode: 'ios' }),
        StoreModule.forFeature(offlineFeatureKey, OfflineReducer),
        StoreModule.forRoot({}, { metaReducers: [clearState] }),
        EffectsModule.forRoot(),
        EffectsModule.forFeature([OfflineEffects]),
        DataModule.forRoot(),
        SessionModule,
        environment.prodBuild
            ? ErrorHandlingModule.forRoot()
            : StoreDevtoolsModule.instrument({
                  maxAge: 25,
                  logOnly: false,
                  name: 'MBD 2.0'
              }),

        LoggingModule.forRoot(defaultEnv.logging),
        SecurityModule.forRoot({
            ...defaultEnv.security,
            production: defaultEnv.production,
            timeoutMS: defaultEnv.timeoutMS,
            persistentKeys: [
                ...Object.values(ParentPersistentKeys),
                AppInitService.STORAGE_CURRENT_ENV,
                ...Object.values(ArrivalNoteKeys)
            ]
        }),
        LoginModule,
        CalendarModule,
        DesignSystemModule,
        NavModule,
        EventsModule,
        TranslateModule.forRoot({
            isolate: true,
            extend: true
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: APICancelInterceptor },
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: LBRouterInterceptor },
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: APIKeyInterceptor },
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: UserAgentInterceptor },
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: NativeHttpInterceptor }, //This should ALWAYS be the last interceptor
        AppInitService,
        DeepLinkingService,
        {
            provide: AuthenticationService,
            useClass: ParentAuthenticationService
        },
        {
            provide: AppVersionService,
            useClass: ParentAppVersionService
        },
        GlobalErrorHandler,
        {
            provide: ApiRetryConfig,
            useValue: defaultEnv.errorHandling
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [SQLiteDBService, Platform, AppInitService, DeepLinkingService, OFFLINE_SERVICE],
            multi: true
        },
        {
            provide: DEFAULT_ENV,
            useValue: defaultEnv
        },
        {
            provide: APP_DETAIL,
            useValue: {
                APP_NAME: packageJson.name
            }
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    public static readonly DEFAULT_LANG = 'en-us'

    constructor(
        private translate: TranslateService,
        private platform: Platform,
        private router: Router,
        private location: Location,
        @Optional() private routerOutlet: IonRouterOutlet
    ) {
        this.translate.setDefaultLang(AppModule.DEFAULT_LANG)
        this.translate.use(AppModule.DEFAULT_LANG)
        this.translate.setTranslation('en-us', enUS)

        this.platform.backButton.subscribeWithPriority(1, () => {
            if (this.router.url.includes('/activity-feed') || this.router.url.includes('/login')) {
                App.exitApp()
            } else if (!!this.routerOutlet && this.routerOutlet.canGoBack()) {
                this.routerOutlet.pop()
            } else {
                this.location.back()
            }
        })
    }
}
