import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { ArrivalNote, EntryTimes } from '../models/arrival-note'
import { ModalController } from '@ionic/angular'
import { TimeInputComponent } from '@bh/design-system'
import { DatePipe } from '@angular/common'
import { FormGroup } from '@angular/forms'

@Injectable({
    providedIn: 'root'
})
export class ArrivalNotesService {
    constructor(
        private httpClient: HttpClient,
        protected modalCtrl: ModalController,
        private datePipe: DatePipe
    ) {}

    public submitArrivalNotes(dependentId: string, arrivalNotes: ArrivalNote): Observable<void> {
        return this.httpClient
            .post(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/arrival_notes/v2`,
                arrivalNotes
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public deleteArrivalNoteEntry(dependentId: string, entryId: string): Observable<void> {
        return this.httpClient
            .delete(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/arrival_note/${entryId}`
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    private _getFrequentEntries(dependantId: string): Observable<any> {
        return this.httpClient.get(
            `${
                environment.config().security.backendHost
            }/dependent/${dependantId}/arrival_notes/frequent_entries`
        )
    }

    public getFrequentEntries(dependantId: string): Observable<EntryTimes> {
        return this._getFrequentEntries(dependantId).pipe(
            map((data) => {
                return new EntryTimes(data?.entry_times || {})
            })
        )
    }

    public async setTimeChangeOptions(inputTime: any, formSec: FormGroup, entries?: Array<string>, isTentativePickUpTime: boolean = false): Promise<string> {
        const timeVal = (this.datePipe.transform(inputTime, 'hh:mm a') ?? '').toLowerCase()
        const modal = await this.modalCtrl.create({
            component: TimeInputComponent,
            showBackdrop: true,
            cssClass: 'manualTP',
            componentProps: {
                previousTime: timeVal,
                timeInputValue: timeVal.replace(/ [ap]m/g, ''),
                anteMeridiem: timeVal ? timeVal.endsWith('am') : !isTentativePickUpTime,
                frequentEntries: entries
            }
        })
        modal.present()
        const { data } = await modal.onWillDismiss()
        if (data !== null && formSec.pristine) {
            (this.datePipe.transform(data, 'hh:mm a') ?? '').toLowerCase() !== timeVal
                ? formSec.markAsDirty()
                : formSec.markAsPristine()
        }
        return data ?? inputTime
    }
}
