<ion-content>
    <ng-container
        *ngTemplateOutlet="
            detailHeader;
            context: {
                shareable: isShareable$ | async,
                hasAttachment: !!attachmentId,
                media: attachmentId | photo | async
            }
        "
    ></ng-container>

    <div class="details-container">
        <swiper
            #swiper
            class="main-slides"
            [config]="slideOpts"
            (touchstart)="touchStart()"
            (touchEnd)="touchEnd()"
            (sliderMove)="onSliderMover()"
            (slideChange)="onSlideChange($event[0])"
            (reachBeginning)="addSlideStart($event[0])"
            (reachEnd)="addSlideEnd($event[0])"
            (beforeSlideChangeStart)="onSlideWillChange($event[0])"
            (slideChangeTransitionStart)="slideChangeTransitionStart()"
            (slideChangeTransitionEnd)="slideChangeTransitionEnd()"
        >
            <ng-template swiperSlide *ngFor="let item of detailSlideList">
                <bh-document-detail-item
                    class="zoomContainer"
                    *ngIf="item && !item.isDailyReport; else dailyReport"
                    [item]="item"
                    [changedSlide]="pauseAnyMedia"
                ></bh-document-detail-item>
                <ng-template #dailyReport>
                    <bh-daily-report
                        [dependentId]="item.dependentId"
                        [centerId]="item.centerId"
                        [date]="(item.sortDate | date : 'yyyy-MM-dd') || ''"
                    ></bh-daily-report>
                </ng-template>
            </ng-template>
        </swiper>
    </div>
</ion-content>

<ng-template
    #detailHeader
    let-shareable="shareable"
    let-hasAttachment="hasAttachment"
    let-media="media"
>
    <bh-nav-header
        [defaultHref]="''"
        [title]="(type === 'observation' && !hasAttachment ? 'documents.developmentalobservation' : 'documents.' + type) | translate"
        slot="fixed"
        [endClick]="shareable && processFile.bind(this, media, mediaShareType.share)"
        [mediaUrl]="hasAttachment && media.url"
        (backButtonClick)="closeMemoryDetails()"
    >
        <ng-template #asyncContent>
            <ng-container
                *ngTemplateOutlet="
                    type === 'report'
                        ? noAttachmentDailyReportButtons
                        : hasAttachment && !isSwiping && shareable !== null
                        ? shareable
                            ? shareableAttachmentButtons
                            : nonShareableAttachmentButtons
                        : null;
                    context: { media, report: report$ | async }
                "
            ></ng-container>
        </ng-template>
    </bh-nav-header>
</ng-template>

<ng-template #shareableAttachmentButtons let-media="media">
    <ion-buttons end>
        <img
            class="download"
            alt="download"
            src="assets/media-download.svg"
            appPreventClick
            [throttleTime]="4000"
            (throttledClick)="processFile(media, mediaShareType.download)"
        />

        <img
            class="share"
            alt="share"
            src="assets/share.svg"
            appPreventClick
            [throttleTime]="4000"
            (throttledClick)="processFile(media, mediaShareType.share)"
        />
    </ion-buttons>
</ng-template>

<ng-template #nonShareableAttachmentButtons let-media="media">
    <ion-buttons end (click)="processFile(media, mediaShareType.share)">
        <img class="share" alt="share" src="assets/info-dark.svg" />
    </ion-buttons>
</ng-template>

<ng-template #noAttachmentDailyReportButtons let-reportDoc="report">
    <ion-buttons end *ngIf="reportDoc?.id">
        <img
            class="download"
            alt="{{ 'dailyReport.download' | translate }}"
            src="assets/media-download.svg"
            appPreventClick
            [throttleTime]="4000"
            (throttledClick)="shareDownloadReport(reportDoc.id, 'download')"
        />
        <img
            class="share"
            alt="{{ 'dailyReport.share' | translate }}"
            src="assets/share.svg"
            appPreventClick
            [throttleTime]="4000"
            (throttledClick)="shareDownloadReport(reportDoc.id, 'share')"
        />
    </ion-buttons>
</ng-template>
